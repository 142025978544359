import React from "react"

export default function Select(props) {
    return (
        <span>
            <select
                name={props.name}
                id={props.id}
                class={props.cssClass}
                onChange={props.onChange}
            >
                {props.data.map((data) => (
                    <option value={data.value} selected={data.selected}>
                        {data.name}
                    </option>
                ))}
            </select>
        </span>
    )
}
