import React from "react"
import { useRef, useState, useEffect } from "react"
import DashboardCard from "./DashboardCard"
import { IN_OUT_STATUS, SHOW_TYPE } from "../../settings/app_constants"
import ActualNextMonth from "./ActualNextMonth"
import GraphDoughnut from "./GraphDoughnut"
import ChartDataLabels from "chartjs-plugin-datalabels"
import dashboardService from "../../services/dashboard"
import LoadingImg from "../Loading"
import { getCurrentDateTime } from "../../date-common"
import { cssGetProperty } from "../../graph-common"

export default function EvidenceStatusCardList(props) {
    const moment = () => {
        let el = document.getElementById("IDS_MOMENT_PROSIM")
        return el != null ? el.innerText : ""
    }
    const emptyInfo = { nazov: moment(), pocet: "" }
    const emptyGraph = {
        __total__: 0,
        labels: [""],
        datasets: [
            {
                data: [1],
                borderWidth: 1,
                backgroundColor: ["#e0e0e0"],
            },
        ],
    }
    const [loading, setLoading] = useState(true)
    const [cmnth, setCmnth] = useState(null)
    const [cardData, setCardData] = useState(new Array(9).fill(emptyInfo))
    const [graphData, setGraphData] = useState(new Array(2).fill(emptyGraph))
    const [reload, setReload] = useState(true)
    const [refreshDateTime, setRefreshDateTime] = useState("")

    //const [btnClick, _setBtnClick] = useState(false)
    //const btnClickRef = React.useRef(btnClick)

    const cards = [
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + IN_OUT_STATUS.ALL + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.ALL, //vsetci
            bgClass: "dashboard-card-color1",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.ALL +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.BAD_MARKINGS + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.BAD_MARKINGS, //s chybnym znacenim
            bgClass: "dashboard-card-color7",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.BAD_MARKINGS +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.MISSING_ALL_DAY + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.MISSING_ALL_DAY, //chyba cely den
            bgClass: "dashboard-card-color5",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.MISSING_ALL_DAY +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.MISSING + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.MISSING, //s chybajucimi hodinami
            bgClass: "dashboard-card-color2",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.MISSING +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.MISSING_2_4 + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.MISSING_2_4, //s chybajucimi hodinami 2-4h
            bgClass: "dashboard-card-color3",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.MISSING_2_4 +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.MISSING_OVER_4 + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.MISSING_OVER_4, //s chybajucimi hodinami >4h
            bgClass: "dashboard-card-color4",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.MISSING_OVER_4 +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.ABSENCES + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.ABSENCES, //s nepritomnostami
            bgClass: "dashboard-card-color6",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.ABSENCES +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.ABSENCES_OVER_4 + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.ABSENCES_OVER_4, //s nepritomnostami nad 4h
            bgClass: "dashboard-card-color8",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.ABSENCES_OVER_4 +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-evidence",
            id: "evidence" + SHOW_TYPE.PN_OCR_URAZ + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.PN_OCR_URAZ, //s PN, ocr, uraz
            bgClass: "dashboard-card-color9",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.PN_OCR_URAZ +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
    ]

    const evidenceGroup1 = cards.filter(
        (card) =>
            card.showType == SHOW_TYPE.ALL ||
            card.showType == SHOW_TYPE.BAD_MARKINGS ||
            card.showType == SHOW_TYPE.MISSING_ALL_DAY
    )
    const evidenceGroup2 = cards.filter(
        (card) =>
            card.showType == SHOW_TYPE.MISSING ||
            card.showType == SHOW_TYPE.MISSING_2_4 ||
            card.showType == SHOW_TYPE.MISSING_OVER_4
    )
    const evidenceGroup3 = cards.filter(
        (card) =>
            card.showType == SHOW_TYPE.ABSENCES ||
            card.showType == SHOW_TYPE.ABSENCES_OVER_4 ||
            card.showType == SHOW_TYPE.PN_OCR_URAZ
    )

    const graphProps = [
        {
            link: [
                "evidence" + SHOW_TYPE.BAD_MARKINGS + "_lnk",
                "evidence" + IN_OUT_STATUS.ALL + "_lnk",
            ],
        },
        {
            link: [
                "evidence" + SHOW_TYPE.MISSING_ALL_DAY + "_lnk",
                "evidence" + IN_OUT_STATUS.ALL + "_lnk",
            ],
        },
    ]

    /*
    const rgb2hex = (rgb) => {
        return rgb.indexOf("rgb") >= 0
            ? "#" +
                  rgb
                      .slice(4, -1)
                      .split(",")
                      .map((x) => (+x).toString(16).padStart(2, 0))
                      .join("")
            : rgb
    }

    const cssGetProperty = (id, property) => {
        var el

        if (null != (el = document.getElementById(id)))
            return rgb2hex(
                window.getComputedStyle(el).getPropertyValue(property)
            )

        return undefined
    }
    */

    const makeGraph = (data, ind1, ind2, col1, col2, nazov2) => {
        let val = [data[ind1].pocet, data[ind2].pocet - data[ind1].pocet]
        let sum = val[0] + val[1]

        let el = document.getElementById(nazov2)
        if (el != null) nazov2 = el.innerText

        return sum > 0
            ? {
                  __total__: sum,
                  labels: [data[ind1].nazov, nazov2],
                  datasets: [
                      {
                          data: val,
                          borderWidth: 1,
                          backgroundColor: [
                              cssGetProperty(col1, "background-color"),
                              cssGetProperty(col2, "background-color"),
                          ],
                      },
                  ],
              }
            : emptyGraph
    }

    const getData = async () => {
        setLoading(true)

        const zar = document.querySelectorAll(
            "select[name='zar[]'] option:checked"
        )
        const odd = document.querySelectorAll(
            "select[name='odd[]'] option:checked"
        )
        const vm = document.querySelectorAll(
            "select[name='vm[]'] option:checked"
        )
        /* bez MultiSelect
        const zar = document.querySelectorAll("input[name='zar[]']:checked")
        const odd = document.querySelectorAll("input[name='odd[]']:checked")
        const vm = document.querySelectorAll("input[name='vm[]']:checked")
        */

        const selectedZar = Array.prototype.slice
            .call(zar)
            .map((option) => option.value)
        const selectedOdd = Array.prototype.slice
            .call(odd)
            .map((option) => option.value)
        const selectedVm = Array.prototype.slice
            .call(vm)
            .map((option) => option.value)

        dashboardService
            .getAjaxDataPost(
                {
                    act: "getDashboardInfo1",
                    zar: selectedZar,
                    odd: selectedOdd,
                    vm: selectedVm,
                    cmnth:
                        document.getElementById("cmnth") != null
                            ? document.getElementById("cmnth").value
                            : "",
                    filtVeduci:
                        document.getElementById("filtVeduci") != null
                            ? document.getElementById("filtVeduci").value
                            : 0,
                    CSRFToken: document.getElementById("CSRFToken").value,
                },
                {
                    "Content-Type": "multipart/form-data",
                }
            )
            .then((response) => {
                setLoading(false)
                setCardData(response.data)
                setRefreshDateTime(getCurrentDateTime())

                setGraphData([
                    makeGraph(
                        response.data,
                        1,
                        0,
                        "colorGraph7",
                        "colorGraph1",
                        "IDS_BEZ_CHYBNEHO_ZNACENIA"
                    ),
                    makeGraph(
                        response.data,
                        2,
                        0,
                        "colorGraph5",
                        "colorGraph1",
                        "IDS_CHYBA_CELY_DEN_BEZ"
                    ),
                ])
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }

    const handleRefresh = (cmnth) => {
        let el = document.getElementById("cmnth")
        let mes = ""
        if (el != null) mes = document.getElementById("cmnth").value
        if (mes == "") mes = document.getElementById("DEFAULT_CMNTH").innerText
        props.handleRefreshCmnth(cmnth)
        setCmnth(mes)
        //console.log("ada")
        //getData()
        setReload((previous) => !previous)
    }

    useEffect(() => {
        document
            .getElementById("applyFilter")
            .addEventListener("click", function () {
                //getData()
                setReload((previous) => !previous)
            })
    }, [])

    useEffect(() => {
        if (cardData[0].pocet !== "") {
            $(".dashboard-evidence").each(function () {
				if ($(this).tooltip("instance") !== undefined)
					$(this).tooltip("destroy");
				$(this).tooltip({ content: $(this).attr("title") })
            })
        }
    }, [cardData])

    useEffect(() => {
        /*
        if (
            document
                .querySelector("#mainTabs li.nav-item .nav-link.active")
                .getAttribute("id") == "act-att-status"
        ) {
            */
        getData()
        //}
    }, [reload, props.reload])

    return (
        <div className="h-100">
            <div className="container dashboard-container d-flex flex-column h-100">
                <div className="row">
                    <div className="w-100">
                        <div class="container">
                            <div class="row">
                                <div class="col-5 pr-0">
                                    <b>
                                        {
                                            document.getElementById(
                                                "evidenceTxt"
                                            ).value
                                        }
                                        {refreshDateTime}
                                    </b>
                                    <LoadingImg loading={loading} />
                                </div>
                                <div class="col pl-0 right">
                                    <ActualNextMonth
                                        handleRefreshCards={handleRefresh}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row cardGroupBorder p-0">
                    <div className="container">
                        <div className="row dashboard-container-row">
                            {evidenceGroup1.map((card, index) => (
                                <DashboardCard
                                    tabClass={card.tabClass}
                                    id={card.id}
                                    zostFilter={card.zostFilter}
                                    showType={card.showType}
                                    style={card.style}
                                    bgClass={card.bgClass}
                                    link={card.link}
                                    data={cardData[index]}
                                />
                            ))}
                        </div>
                        <div className="row dashboard-container-row">
                            {graphProps.map((data, index) => (
                                <GraphDoughnut
                                    plugins={[ChartDataLabels]}
                                    graph={data}
                                    data={graphData[index]}
                                    width="200px"
                                    useOnClick={true}
                                    enableTooltip={
                                        graphData[index].__total__ > 0
                                    }
                                    formatter={(value, ctx) => {
                                        return graphData[index].__total__ > 0
                                            ? (
                                                  (value * 100) /
                                                  graphData[index].__total__
                                              ).toFixed(0) + "%"
                                            : ""
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row dashboard-container-row cardGroupBorder" style={{height: '100%'}}>
                    <div className="container">
                        <div className="row dashboard-container-row">
                            {evidenceGroup2.map((card, index) => (
                                <DashboardCard
                                    tabClass={card.tabClass}
                                    id={card.id}
                                    zostFilter={card.zostFilter}
                                    showType={card.showType}
                                    style={card.style}
                                    bgClass={card.bgClass}
                                    link={card.link}
                                    data={cardData[index + 3]}
                                />
                            ))}
                            {evidenceGroup3.map((card, index) => (
                                <DashboardCard
                                    tabClass={card.tabClass}
                                    id={card.id}
                                    zostFilter={card.zostFilter}
                                    showType={card.showType}
                                    style={card.style}
                                    bgClass={card.bgClass}
                                    link={card.link}
                                    data={cardData[index + 6]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
