import React from "react"
import { useState, useEffect } from "react"
import dashboardService from "../../services/dashboard"
import LoadingImg from "../Loading"

export default function InOutUpdateTime(props) {
    const [lastUpdateTime, setLastUpdateTime] = useState("")
    const [loading, setLoading] = useState(false)
    const [btnClick, _setBtnClick] = useState(false)

    useEffect(() => {
        document
            .getElementById("applyFilter")
            .addEventListener("click", function () {
                //setBtnClick(!btnClickRef.current)
                getData()
            })
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)

        dashboardService
            .getAjaxDataGet({
                act: "getPritLastRuntime",
                CSRFToken: CSRFToken.value,
            })
            .then((response) => {
                setLoading(false)
                setLastUpdateTime(response.data.pritomnostLastRun)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    return <span>{lastUpdateTime}</span>
}
