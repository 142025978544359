export function getCurrentDate(separator = "") {
    let newDate = new Date()
    let date = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()

    return `${year}${separator}${
        month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`
}

export function getCurrentDateTime() {
    let today = new Date()
    let day = today.getDate()
    let month = today.getMonth() + 1
    let year = today.getFullYear()
    let h = today.getHours()
    let m = today.getMinutes()
    let s = today.getSeconds()

    return (
        (day < 10 ? "0" + day : day) +
        "." +
        (month < 10 ? "0" + month : month) +
        "." +
        year +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (m < 10 ? "0" + m : m) //+
        //":" +
        //(s < 10 ? "0" + s : s)
    )

    return `
    ${day < 10 ? `0${day}` : `${day}`}
    ${month < 10 ? `0${month}` : `${month}`}
    ${year}
    ${` `}
    ${h < 10 ? `0${h}` : `${h}`}${":"}
    ${m < 10 ? `0${m}` : `${m}`}${":"}
    ${s < 10 ? `0${s}` : `${s}`}
    `
}

export function getHMFromDecimal(decimalTimeString) {
    let decimalTime = parseFloat(decimalTimeString)
    decimalTime = decimalTime * 60 * 60
    let h = Math.floor(decimalTime / (60 * 60))
    decimalTime = decimalTime - h * 60 * 60
    let m = Math.floor(decimalTime / 60)

    return (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m)
}
