import React from "react"

export default function DashboardBadge(props) {
    return (
        <div className="col d-flex justify-content-center">
            <div
                className={`text-white position-relative d-flex justify-content-center text-center align-items-center mb-3 shadow dashboard-task task-card ${props.bgClass} ${props.tabClass}`}
                title={props.data.hint}
            >
                {props.data.pernoID !== null ? (
                    <a
                        href={
                            props.link +
                            (props.data.pernoID !== undefined
                                ? props.data.pernoID
                                : "")
                        }
                        id={props.id}
                    >
                        {props.data.nazov}
                    </a>
                ) : (
                    props.data.nazov
                )}
                {"  "}
                <span
                    className="badge badge-pill badge-light position-absolute"
                    style={{ top: "0", right: "0" }}
                >
                    {props.data.pocet}
                </span>
            </div>
        </div>
    )
}
