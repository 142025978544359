import React from "react"
import { useRef, useState, useEffect } from "react"
import DashboardBadge from "./DashboardBadge"
import { IN_OUT_STATUS, SHOW_TYPE } from "../../settings/app_constants"
import dashboardService from "../../services/dashboard"
import LoadingImg from "../Loading"

var __tooltipInit = false

export default function TasksCardList(props) {
    const moment = () => {
        let el = document.getElementById("IDS_MOMENT_PROSIM")
        return el != null ? el.innerText : ""
    }
    const emptyInfo = { nazov: moment(), pocet: "" }

    const [loading, setLoading] = useState(true)
    const [cmnth, setCmnth] = useState(null)
    const [reload, setReload] = useState(true)
    const [cardData, setCardData] = useState(new Array(6).fill(emptyInfo))

    let tasks1 = []
    tasks1.push({
        tabClass: "dashboard-task",
        id: "task_last_perno_lnk",
        //naposledy sprac. zam.
        bgClass: "dashboard-card-color6",
        refreshFunc: useRef(null),
        link: "getPage.php?rp=1&perno=",
    })
    if (document.getElementById("isSchvalovatelZ") !== null) {
        tasks1.push({
            tabClass: "dashboard-task",
            id: "task_ziad_cakajuce_schv_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            bgClass: "dashboard-card-color1",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=248&&spVybDat=1&filterZiadanky=0&my_from=" +
                //&spVybDat=1&my_from=202112&my_to=202311&filterZiadanky=1
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        })
    }
    tasks1.push(
        {
            tabClass: "dashboard-task",
            id: "task" + SHOW_TYPE.BAD_MARKINGS + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            bgClass: "dashboard-card-color7",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.BAD_MARKINGS +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-task",
            id: "task" + SHOW_TYPE.MISSING_ALL_DAY + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.MISSING_ALL_DAY, //chyba cely den
            bgClass: "dashboard-card-color5",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.MISSING_ALL_DAY +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        },
        {
            tabClass: "dashboard-task",
            id: "task" + SHOW_TYPE.MISSING_OVER_4 + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            showType: SHOW_TYPE.MISSING_OVER_4, //s nepritomnostami nad 4h
            bgClass: "dashboard-card-color4",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=0&resetFilters=1&show=" +
                SHOW_TYPE.MISSING_OVER_4 +
                (cmnth !== null ? "&cmnth=" + cmnth : ""),
        }
    )

    const getData = async () => {
        setLoading(true)

        const zar = document.querySelectorAll(
            "select[name='zar[]'] option:checked"
        )
        const odd = document.querySelectorAll(
            "select[name='odd[]'] option:checked"
        )
        const vm = document.querySelectorAll(
            "select[name='vm[]'] option:checked"
        )
        /* bez MultiSelect
        const zar = document.querySelectorAll("input[name='zar[]']:checked")
        const odd = document.querySelectorAll("input[name='odd[]']:checked")
        const vm = document.querySelectorAll("input[name='vm[]']:checked")
        */

        const selectedZar = Array.prototype.slice
            .call(zar)
            .map((option) => option.value)
        const selectedOdd = Array.prototype.slice
            .call(odd)
            .map((option) => option.value)
        const selectedVm = Array.prototype.slice
            .call(vm)
            .map((option) => option.value)

        dashboardService
            .getAjaxDataPost(
                {
                    act: "getDashboardTaskInfo",
                    zar: selectedZar,
                    odd: selectedOdd,
                    vm: selectedVm,
                    cmnth:
                        props.cmnth != null && props.cmnth != -1
                            ? props.cmnth
                            : "",
                    filtVeduci:
                        document.getElementById("filtVeduci") != null
                            ? document.getElementById("filtVeduci").value
                            : 0,
                    CSRFToken: document.getElementById("CSRFToken").value,
                },
                {
                    "Content-Type": "multipart/form-data",
                }
            )
            .then((response) => {
                setLoading(false)
                setCardData(response.data)
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }

    useEffect(() => {
        document
            .getElementById("applyFilter")
            .addEventListener("click", function () {
                //getData()
                setReload((previous) => !previous)
            })

        //getData()
        setReload((previous) => !previous)
    }, [])

    useEffect(() => {
        getData()
    }, [reload, props.reload])

    useEffect(() => {
        if (props.cmnth != null) {
            //getData()
            setReload((previous) => !previous)
        }
        setCmnth(props.cmnth)
    }, [props.cmnth])

    useEffect(() => {
        if (!__tooltipInit && cardData[0].nazov !== moment()) {
            __tooltipInit = true
            $(".dashboard-task").each(function () {
                $(this).tooltip({ content: $(this).attr("title") })
            })
        }
    }, [cardData])

    return (
        <div className="h-100">
            <div className="container dashboard-container d-flex flex-column h-100">
                <div className="row">
                    <div className="w-100">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <b>
                                        {
                                            document.getElementById("tasksTxt")
                                                .value
                                        }
                                    </b>
                                    <LoadingImg loading={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row cardGroupBorder p-1 flex-grow-1">
                    <div className="container dashboard-container-row d-flex flex-column h-100">
                        <div className="row">
                            {tasks1.map((task, index) =>
                                task.id == "task_last_perno_lnk" ||
                                cardData[index].pocet > 0 ? (
                                    <DashboardBadge
                                        tabClass={task.tabClass}
                                        id={task.id}
                                        zostFilter={task.zostFilter}
                                        style={task.style}
                                        bgClass={task.bgClass}
                                        link={task.link}
                                        data={cardData[index]}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
