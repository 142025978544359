import React from "react"
import { useRef, useState, useEffect } from "react"
import Ints from "./Ints"

export default function CurrentInts(props) {
    //const [currentTab, setCurrentTab] = useState("")
    //const [reload, setReload] = useState("")
    //const [filterChanged, setFilterChanged] = useState(false)

    /*
    useEffect(() => {
        document.querySelector("#mainTabs").addEventListener(
            "click",
            function () {
                setTimeout(function () {
                    //chvilu pockaj, kym si Bootstrap nastavi prislusne triedy "active" a az potom nacitaj hodnotu aktivneho tabu
                    setCurrentTab((previous) =>
                        previous !=
                        document
                            .querySelector(
                                "#mainTabs li.nav-item .nav-link.active"
                            )
                            .getAttribute("id")
                            ? document
                                  .querySelector(
                                      "#mainTabs li.nav-item .nav-link.active"
                                  )
                                  .getAttribute("id")
                            : previous
                    )
                })
            },
            500
        )

        $("select").on("change", function () {
            setFilterChanged(true)
        })
    }, [])

    useEffect(() => {
        if (filterChanged) {
            setReload((previous) => !previous)
            setFilterChanged(false)
        }
    }, [currentTab])
    */

    return (
        <div>
            <div className="container-fluid">
                <div className="mx-auto" style={{ "max-width": "1920px" }}>
                    <div className="row">
                        <div className="d-flex align-items-stretch m-0 p-0">
                            <div className="cardGroupBorder w-100">
                                <Ints
                                    reload={props.reload} /*type={intOption}*/
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
