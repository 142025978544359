import axios, { AxiosResponse } from "axios"

const axiosApiInstance = axios.create({
    baseURL: "",
    timeout: 50000,
})

axiosApiInstance.interceptors.request.use(
    async (config) => {
        //console.log("request")
        //console.log("Starting Request", JSON.stringify(config, null, 2))
        /*
        config.headers = {
            Accept: 'application/json',
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': true,
        };
        */
        return config
    },
    (error) => {
        console.log(error)
        Promise.reject(error)
    }
)

axiosApiInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        /*
        if (error.response?.status === 401) {

        } else if (error.response?.status) {
        //nic - ostatne kody
        } else {*/
        console.log("Pri komunik�cii so serverom vznikla chyba", error.message)
        //}

        return Promise.reject(error)
    }
)

export default axiosApiInstance
