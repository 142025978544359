import React from "react"
import { useRef, useState, useEffect } from "react"
import TasksCardList from "./TasksCardList"
import EvidenceStatusCardList from "./EvidenceStatusCardList"
import PresenceStatusCardList from "./PresenceStatusCardList"

export default function CurrentAttendance(props) {
    const [cmnth, setCmnth] = useState(null)
    //const [currentTab, setCurrentTab] = useState("")
    //const [reload, setReload] = useState("")
    //const [filterChanged, setFilterChanged] = useState(false)

    const handleRefreshCmnth = (cmnth) => {
        if (cmnth != -1) {
            setCmnth(cmnth)
        }
    }

    /*
    useEffect(() => {
        document.querySelector("#mainTabs").addEventListener(
            "click",
            function () {
                setTimeout(function () {
                    //chvilu pockaj, kym si Bootstrap nastavi prislusne triedy "active" a az potom nacitaj hodnotu aktivneho tabu
                    setCurrentTab((previous) =>
                        previous !=
                        document
                            .querySelector(
                                "#mainTabs li.nav-item .nav-link.active"
                            )
                            .getAttribute("id")
                            ? document
                                  .querySelector(
                                      "#mainTabs li.nav-item .nav-link.active"
                                  )
                                  .getAttribute("id")
                            : previous
                    )
                })
            },
            500
        )

        $("select").on("change", function () {
            setFilterChanged(true)
        })
    }, [])

    useEffect(() => {
        if (filterChanged) {
            setReload((previous) => !previous)
            setFilterChanged(false)
        }
    }, [currentTab])
    */

    return (
        <div>
            <div className="container-fluid">
                <div className="mx-auto" style={{ "max-width": "1920px" }}>
                    <div className="row">
                        <div className="col-12 col-md-4 col-xl-2 d-flex align-items-stretch m-0 p-0">
                            <div className="cardGroupBorder w-100">
                                <TasksCardList
                                    cmnth={cmnth}
                                    reload={props.reload}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-xl-5 d-flex align-items-stretch m-0 p-0">
                            <div className="cardGroupBorder w-100">
                                <EvidenceStatusCardList
                                    handleRefreshCmnth={handleRefreshCmnth}
                                    reload={props.reload}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-xl-5 d-flex align-items-stretch m-0 p-0">
                            <div className="cardGroupBorder w-100">
                                <PresenceStatusCardList reload={props.reload} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
