import React from "react"
import { useRef, useState, useEffect } from "react"
import CurrentAttendance from "./CurrentAttendance"
import CurrentInts from "./CurrentInts"

export default function Dashboard(props) {
    const [reload, setReload] = useState(true)
    const [currentTab, setCurrentTab] = useState("aktStavEvidPritTxt")
    const [filterChanged, setFilterChanged] = useState(false)

    useEffect(() => {
        //TODO prepisat do vanilla js
        $("select").on("change", function () {
            setFilterChanged(true)
        })
    }, [])

    useEffect(() => {
        if (currentTab == "aktStavEvidPritTxt") {
            document
                .querySelectorAll(
                    "#obdobieDiv, #divSpVybDatObd, #divSpVybDatDay, #divSpVybDatDay1, #divSpVybDatDay2, #divSpVybDatMonth, #divSpVybDatYear, #divSpVybDatOdDo, #intTypeDiv, #intGroupDiv, #intIntcodeDiv, #intOptionsDiv, #intShowTypeOptionsDiv"
                )
                .forEach((element) => {
                    element.style.display = "none"
                })
        } else {
            document
                .querySelectorAll(
                    "#obdobieDiv, #intTypeDiv, #intGroupDiv, #intIntcodeDiv, #intOptionsDiv, #intShowTypeOptionsDiv"
                )
                .forEach((element) => {
                    element.style.display = "block"
                })

            updateVybDat()
            updateIntOptions()
            spVybDatChangefiltTlac()
        }
        document.querySelector(".filterMainLeftDiv").style.height = "auto"

        if (filterChanged) {
            setReload((previous) => !previous)
            setFilterChanged(false)
        }
    }, [currentTab])

    const handleTabClick = (key) => {
        setCurrentTab((previous) => (previous != key ? key : previous))
    }

    return (
        <>
            <ul className="nav nav-tabs" id="mainTabs" role="tablist">
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        id="act-att-status"
                        data-toggle="tab"
                        href="#act-att-status-href"
                        role="tab"
                        aria-controls="atts"
                        aria-selected="true"
                        onClick={() => handleTabClick("aktStavEvidPritTxt")}
                    >
                        {document.getElementById("aktStavEvidPritTxt").value}
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        id="ints-status"
                        data-toggle="tab"
                        href="#ints-status-href"
                        role="tab"
                        aria-controls="ints"
                        aria-selected="false"
                        onClick={() => handleTabClick("prehladNepritTxt")}
                    >
                        {document.getElementById("prehladNepritTxt").value}
                    </a>
                </li>
            </ul>
            <div className="tab-content" id="mainTabsContent">
                <div
                    className="tab-pane fade show active"
                    id="act-att-status-href"
                    role="tabpanel"
                    aria-labelledby="act-att-status"
                >
                    <CurrentAttendance reload={reload} />
                </div>
                <div
                    className="tab-pane fade"
                    id="ints-status-href"
                    role="tabpanel"
                    aria-labelledby="ints-status"
                >
                    <CurrentInts reload={reload} />
                </div>
            </div>
        </>
    )
}
