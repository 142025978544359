import React from "react"

export default function GraphColors() {
    return (
        <div>
            <div id="colorGraph1" className="dashboard-card-color1"></div>
            <div
                id="colorGraph2"
                className="dashboard-card-color2"
                style={{ display: "none" }}
            ></div>
            <div
                id="colorGraph3"
                className="dashboard-card-color3"
                style={{ display: "none" }}
            ></div>
            <div id="colorGraph4" className="dashboard-card-color4"></div>
            <div id="colorGraph5" className="dashboard-card-color5"></div>
            <div
                id="colorGraph6"
                className="dashboard-card-color6"
                style={{ display: "none" }}
            ></div>
            <div id="colorGraph7" className="dashboard-card-color7"></div>
            <div id="colorGraph8" className="dashboard-card-color8"></div>
            <div id="colorGraph9" className="dashboard-card-color9"></div>
            <div id="colorGraph10" className="dashboard-card-color10"></div>
            <div id="colorGraph11" className="dashboard-card-color11"></div>
            <div id="colorGraph12" className="dashboard-card-color12"></div>
            <div
                id="dashboard-chart-datalabel"
                className="dashboard-chart-datalabel"
            ></div>
            <div
                id="dashboard-chart-legend"
                className="dashboard-chart-legend"
            ></div>
        </div>
    )
}
