import React from "react"
import { useState, useEffect, useRef } from "react"
import dashboardService from "../../services/dashboard"
import Select from "../Select"

export default function ActualNextMonth(props) {
    const [cmnthData, setCmnthData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectChanged, setSelectChanged] = useState(-1)
    const [txtName, setTxtName] = useState("")
    //const cmnthRef = useRef(null)

    useEffect(() => {
        getData()
        $("#cmnth").on("change", function (e) {
            //$(cmnthRef.current).on("change", function (e) {
            handleOnChange(e)
        })
    }, [])

    useEffect(() => {
        $("#cmnth").trigger("chosen:updated")
    }, [cmnthData])

    useEffect(() => {
        props.handleRefreshCards(selectChanged) //pri zmene refresni karty v zozname podla mesiaca
    }, [selectChanged])

    const getData = async () => {
        setLoading(true)

        dashboardService
            .getAjaxDataGet({
                act: "getActualNextMonthYear",
                CSRFToken: CSRFToken.value,
            })
            .then((response) => {
                setLoading(false)
                setCmnthData(response.data.items)
                setTxtName(response.data.name)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const handleOnChange = (e) => {
        setSelectChanged(e.target.value)
        //        console.log("change")
        //        console.log(e.target.value)
    }

    return (
        <div>
            <span className="">{txtName}: </span>
            <Select
                txt={txtName}
                name="cmnth"
                id="cmnth"
                data={cmnthData}
                onChange={handleOnChange}
            />
            {cmnthData.map((data) => data.val)}
        </div>
    )
}
