import React from "react"
import { createRoot } from "react-dom/client"
//import PresenceStatusCardList from "./components/dashboard/PresenceStatusCardList"
//import EvidenceStatusCardList from "./components/dashboard/EvidenceStatusCardList"
//import TasksCardList from "./components/dashboard/TasksCardList"
import GraphColors from "./components/dashboard/GraphColors"
import CurrentAttendance from "./components/dashboard/CurrentAttendance"
import CurrentInts from "./components/dashboard/CurrentInts"
import Dashboard from "./components/dashboard/Dashboard"

document.addEventListener("DOMContentLoaded", () => {
    let domNode = document.getElementById("currentAttendanceContent")
    if (domNode) {
        const root = createRoot(domNode)
        root.render(<CurrentAttendance />)
    }

    domNode = document.getElementById("currentIntsByNVContent")
    if (domNode) {
        const root = createRoot(domNode)
        root.render(<CurrentInts />)
    }

    domNode = document.getElementById("dashboardContent")
    if (domNode) {
        const root = createRoot(domNode)
        root.render(<Dashboard />)
    }

    domNode = document.getElementById("graphColors")
    if (domNode) {
        const root = createRoot(domNode)
        root.render(<GraphColors />)
    }
})
