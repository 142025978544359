import React from "react"
import { useRef, useState, useEffect } from "react"
import { getHMFromDecimal } from "../../date-common"
import GraphDoughnut from "./GraphDoughnut"
import ChartDataLabels from "chartjs-plugin-datalabels"
import dashboardService from "../../services/dashboard"
import LoadingImg from "../Loading"
import { cssGetProperty } from "../../graph-common"
import { INT_TYPE } from "../../settings/app_constants"
import { getCurrentDateTime } from "../../date-common"

export default function Ints(props) {
    const emptyGraph = (header = "", labels = []) => {
        //console.log(header)
        //console.log(labels)
        return {
            enableTooltip: true,
            graphHeader: header,
            total: 0,
            labels: labels,
            datasets: [
                {
                    data: [-1],
                    borderWidth: 1,
                    backgroundColor: ["#e0e0e0"],
                },
            ],
        }
    }

    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(true)
    const [firstLoad, setFirstload] = useState(true)
    const [graphData, setGraphData] = useState(new Array(1).fill(emptyGraph()))
    const [refreshDateTime, setRefreshDateTime] = useState("")

    const makeGraph = (data) => {
        let plan = data.map((x) => x.plan)
        let val = data.map((x) => x.pocet)
        let percent = data.map((x) => x.percent)
        let labels = data.map((x) => x.nazov)
        let hints = data.map((x) => x.hint)
        let header = ""

        let bkgColors = [
            cssGetProperty("colorGraph1", "background-color"),
            cssGetProperty("colorGraph2", "background-color"),
            cssGetProperty("colorGraph3", "background-color"),
            cssGetProperty("colorGraph4", "background-color"),
            cssGetProperty("colorGraph5", "background-color"),
            cssGetProperty("colorGraph6", "background-color"),
            cssGetProperty("colorGraph7", "background-color"),
            cssGetProperty("colorGraph8", "background-color"),
            cssGetProperty("colorGraph9", "background-color"),
            cssGetProperty("colorGraph10", "background-color"),
            cssGetProperty("colorGraph11", "background-color"),
            cssGetProperty("colorGraph12", "background-color"),
        ]

        //let total = ""
        if (hints[0] != "") header = hints[0]
        if (plan[0] != "") plan = plan[0]

        if (plan > -1) {
            let totalPercent = percent
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + parseFloat(currentValue),
                    0
                )
                .toFixed(2)
            percent = [...percent, 100 - totalPercent]
            bkgColors[percent.length - 1] = "#e0e0e0" //zvysok grafu vyfarbi sivou
            //bkgColors[percent.length] = "#e0e0e0"

            //labels = [...labels, "Plan"]
            //console.log(percent)
        }

        let isEmptyData =
            val.length == 0 ||
            (val.length > 0 && val.every((x) => x == val[0] && x == 0))

        //let headerTotal = val
        let total = val
            .reduce(
                (accumulator, currentValue) =>
                    accumulator + parseFloat(currentValue),
                0
            )
            .toFixed(2)

        return !isEmptyData
            ? {
                  enableTooltip: true,
                  graphHeader: header,
                  total: total,
                  plan: plan,
                  //graphHeaderTotal: headerTotal,
                  labels: labels,
                  datasets: [
                      {
                          data: percent,
                          borderWidth: 1,
                          backgroundColor: bkgColors,
                      },
                  ],
              }
            : emptyGraph(header, labels)
    }

    const getData = async () => {
        setLoading(true)

        const zar = document.querySelectorAll(
            "select[name='zar[]'] option:checked"
        )
        const odd = document.querySelectorAll(
            "select[name='odd[]'] option:checked"
        )
        const vm = document.querySelectorAll(
            "select[name='vm[]'] option:checked"
        )

        let int = []
        let act = []
        if (document.getElementById("intOptions").value == INT_TYPE.NV) {
            int = document.querySelectorAll(
                "select[name='intTypeFilter[]'] option:checked"
            )
            act = "getDashboardIntsByType"
        } else if (
            document.getElementById("intOptions").value == INT_TYPE.GROUP
        ) {
            int = document.querySelectorAll(
                "select[name='intGroupFilter[]'] option:checked"
            )
            act = "getDashboardIntsByGroup"
        } else {
            int = document.querySelectorAll(
                "select[name='intIntcodeFilter[]'] option:checked"
            )
            act = "getDashboardIntsByIntcode"
        }

        const selectedZar = Array.prototype.slice
            .call(zar)
            .map((option) => option.value)
        const selectedOdd = Array.prototype.slice
            .call(odd)
            .map((option) => option.value)
        const selectedVm = Array.prototype.slice
            .call(vm)
            .map((option) => option.value)
        const selectedIntData = Array.prototype.slice
            .call(int)
            .map((option) => option.value)

        if (selectedIntData.length == 0) {
            setLoading(false)
            return
        }

        dashboardService
            .getAjaxDataPost(
                {
                    act: act,
                    zar: selectedZar,
                    odd: selectedOdd,
                    vm: selectedVm,
                    intData: selectedIntData,
                    intOptions: document.getElementById("intOptions").value,
                    intShowType:
                        document.getElementById("intShowTypeOptions").value,
                    dateIntervalType:
                        document.getElementById("spVybDat") != null
                            ? document.getElementById("spVybDat").value
                            : "",
                    myFrom:
                        document.getElementById("my_from") != null
                            ? document.getElementById("my_from").value
                            : "",
                    myTo:
                        document.getElementById("my_to") != null
                            ? document.getElementById("my_to").value
                            : "",
                    dateOnly:
                        document.getElementById("spVybDatDay") != null
                            ? document.getElementById("spVybDatDay").value
                            : "",
                    dateOnly1:
                        document.getElementById("spVybDatDay1") != null
                            ? document.getElementById("spVybDatDay1").value
                            : "",
                    dateOnly2:
                        document.getElementById("spVybDatDay2") != null
                            ? document.getElementById("spVybDatDay2").value
                            : "",
                    monthOnly:
                        document.getElementById("spVybDatMonth") != null
                            ? document.getElementById("spVybDatMonth").value
                            : "",
                    yearOnly:
                        document.getElementById("spVybDatYear") != null
                            ? document.getElementById("spVybDatYear").value
                            : "",
                    year1:
                        document.getElementById("spVybDatYear1") != null
                            ? document.getElementById("spVybDatYear1").value
                            : "",
                    year2:
                        document.getElementById("spVybDatYear2") != null
                            ? document.getElementById("spVybDatYear2").value
                            : "",
                    cmnth:
                        document.getElementById("cmnth") != null
                            ? document.getElementById("cmnth").value
                            : "",
                    filtVeduci:
                        document.getElementById("filtVeduci") != null
                            ? document.getElementById("filtVeduci").value
                            : 0,
                    CSRFToken: document.getElementById("CSRFToken").value,
                },
                {
                    "Content-Type": "multipart/form-data",
                }
            )
            .then((response) => {
                setLoading(false)
                setRefreshDateTime(getCurrentDateTime())
                //setGraphTitle([])

                //console.log("response.data")
                //console.log(response.data)

                let gDataArr = []
                let gData = []
                for (const [key, data] of Object.entries(response.data)) {
                    gDataArr = []
                    //console.log(data.length)
                    //if (data.length) {
                    data.map((item, index) => {
                        //gDataArr.push(item)
                        gDataArr = [...gDataArr, item]
                    })

                    //gData.push(makeGraph(gDataArr))
                    gData = [...gData, makeGraph(gDataArr)]
                    //}
                    //gData.push(emptyGraph)
                    //setGraphTitle((prevGraphTitle) => [...prevGraphTitle, key])
                }

                setGraphData(gData)
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }

    useEffect(() => {
        document
            .getElementById("applyFilter")
            .addEventListener("click", function () {
                //console.log(document.getElementById("intOptions").value)
                //getData()
                setReload((previous) => !previous)
            })
        //getData()
        setReload((previous) => !previous)
    }, [])

    useEffect(() => {
        /*
        if (
            firstLoad ||
            document
                .querySelector("#mainTabs li.nav-item .nav-link.active")
                .getAttribute("id") == "ints-status"
        ) {
            */
        getData()
        setFirstload(false)
        //}
    }, [reload, props.reload])

    return (
        <div className="h-100">
            <b>
                {document.getElementById("stavKTxt").innerText}
                {refreshDateTime}
            </b>
            <LoadingImg loading={loading} />

            <div className="container-fluid dashboard-container d-flex flex-column h-100">
                <div className="row dashboard-container-row">
                    {graphData.map((data, index) => (
                        <div>
                            {/*data.datasets.data
                                ? data.datasets.data.length
                                : -1*/}

                            {/*console.log(
                                data.datasets.map((item) => {
                                    console.log("*")
                                    console.log(item.data)
                                    console.log(
                                        item.data.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator + currentValue,
                                            0
                                        )
                                    )
                                    console.log("/")
                                })
                            )*/}
                            {/*console.log(
                                data.datasets.data
                                    ? data.datasets.data.reduce(
                                          (accumulator, currentValue) =>
                                              accumulator + currentValue,
                                          0
                                      )
                                    : 0
                            )*/}
                            <div style={{ marginLeft: "25px" }}>
                                <b>{data.graphHeader}</b>
                                {document.getElementById("intShowTypeOptions")
                                    .value == "1"
                                    ? ", " +
                                      document.getElementById("planTxt").value +
                                      " " +
                                      (isNaN(data.plan)
                                          ? "0,00h,"
                                          : new Intl.NumberFormat(
                                                "sk-SK",
                                                {}
                                            ).format(
                                                Number(data.plan).toFixed(2)
                                            ) + "h,")
                                    : ""}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: "&nbsp;&Sigma; ",
                                    }}
                                />
                                {new Intl.NumberFormat("sk-SK", {}).format(
                                    Number(data.total).toFixed(2)
                                ) + "h"}
                                {data.plan >= 0
                                    ? ", " +
                                      /*
                                      ((data.total / data.plan) * 100).toFixed(
                                          2
                                      )*/
                                      new Intl.NumberFormat("sk-SK", {}).format(
                                          Number(
                                              (data.total / data.plan) * 100
                                          ).toFixed(2)
                                      ) +
                                      "%"
                                    : ""}
                            </div>
                            <GraphDoughnut
                                plugins={[ChartDataLabels]}
                                //graph={prop}
                                data={data}
                                enableTooltip={data.enableTooltip}
                                width="350px"
                                /*
                                width={String(
                                    350 +
                                        (data.labels.length > 8
                                            ? (data.labels.length - 8) * 25
                                            : 0)
                                ).concat("px")}
                                */
                                useOnClick={false}
                                formatter={(value, ctx) => {
                                    return value > 3
                                        ? Number(value).toFixed(0) + "%"
                                        : ""
                                    /*
                                    return value > 0 && data.total > 0
                                        ? ((value * data.total) / 100).toFixed(
                                              0
                                          )
                                        : ""
                                        */
                                    /*
                                    return value > 0 &&
                                        data.total > 0 &&
                                        (value * 100) / data.total > 3 //% zobraz len ak je nad 3%
                                        ? ((value * 100) / data.total).toFixed(
                                              0
                                          ) + "%"
                                        : ""
                                        */
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
