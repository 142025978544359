import http from "../http-common"

const dashboardService = {
    getAjaxDataPost: async (data, header) => {
        return http.post(`ajax.php`, data, { headers: header })
    },
    getAjaxDataGet: async (data) => {
        return http.get(`ajax.php?act=${data.act}&CSRFToken=${data.CSRFToken}`)
    },
}

export default dashboardService
