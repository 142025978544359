export const IN_OUT_STATUS = {
    ALL: -1,
    IN: 0,
    OUT: 1,
    OUT_AGAINST_PLANNED_SHIFT: 6,
    IN_AGAINST_PLANNED_SHIFT: 26,
    TOTAL_AGAINST_PLANNED_SHIFT: 50,
}

export const SHOW_TYPE = {
    ALL: -1, //Spolu
    BAD_MARKINGS: 2, //S chybnym znacenim
    MISSING: 3, //S chybajucimi hodinami
    MISSING_2_4: 30, //S chybajucimi hodinami 2-4h
    MISSING_OVER_4: 40, //S chybajucimi hodinami >4h
    MISSING_ALL_DAY: 41, //Chyba cely den
    ABSENCES: 11, //S nepritomnostami
    ABSENCES_OVER_4: 1, //S nepritomnostami nad 4h
    PN_OCR_URAZ: 4, //PN, OCR, URAZ
    COMPENSATORY_LEAVE: 38, //NV
}

export const INT_TYPE = {
    NV: 0,
    GROUP: 1,
    INTCODE: 2,
}
