export function rgb2hex(rgb) {
    return rgb.indexOf("rgb") >= 0
        ? "#" +
              rgb
                  .slice(4, -1)
                  .split(",")
                  .map((x) => (+x).toString(16).padStart(2, 0))
                  .join("")
        : rgb
}

export function cssGetProperty(id, property) {
    var el

    if (null != (el = document.getElementById(id)))
        return rgb2hex(window.getComputedStyle(el).getPropertyValue(property))

    return undefined
}
