import React from "react"
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { cssGetProperty } from "../../graph-common"

ChartJS.register(ArcElement, Title, Tooltip, Legend)

export default function GraphDoughnut(props) {
    const options = {
        onClick: (evt, el, chart) => {
            if (props.useOnClick && el.length > 0 && props.data.__total__ > 0) {
                var el = document.getElementById(props.graph.link[el[0].index])
                if (el != null) el.click()
            }
        },
        plugins: {
            tooltip: {
                enabled: () => {
                    return props.enableTooltip
                },
            },
            datalabels: {
                formatter: props.formatter,
                color: cssGetProperty("dashboard-chart-datalabel", "color"),
                font: {
                    family: cssGetProperty(
                        "dashboard-chart-datalabel",
                        "font-family"
                    ),
                    size: cssGetProperty(
                        "dashboard-chart-datalabel",
                        "font-size"
                    ),
                    weight: cssGetProperty(
                        "dashboard-chart-datalabel",
                        "font-weight"
                    ),
                    style: cssGetProperty(
                        "dashboard-chart-datalabel",
                        "font-style"
                    ),
                },
            },
            legend: {
                align: "start",
                labels: {
                    color: cssGetProperty("dashboard-chart-legend", "color"),
                    font: {
                        family: cssGetProperty(
                            "dashboard-chart-legend",
                            "font-family"
                        ),
                        size: cssGetProperty(
                            "dashboard-chart-legend",
                            "font-size"
                        ),
                        weight: cssGetProperty(
                            "dashboard-chart-legend",
                            "font-weight"
                        ),
                        style: cssGetProperty(
                            "dashboard-chart-legend",
                            "font-style"
                        ),
                    },
                },
                onHover: (evt, item, legend) => {
                    legend.chart.data.datasets[0].backgroundColor.forEach(
                        (color, index, colors) => {
                            colors[index] =
                                index === item.index || color.length === 9
                                    ? color
                                    : color + "1D"
                        }
                    )
                    legend.chart.update()
                },
                onLeave: (evt, item, legend) => {
                    legend.chart.data.datasets[0].backgroundColor.forEach(
                        (color, index, colors) => {
                            colors[index] =
                                color.length === 9 ? color.slice(0, -2) : color
                        }
                    )
                    legend.chart.update()
                },
                onClick: (evt, item, legend) => {
                    if (props.useOnClick && props.data.__total__ > 0) {
                        var el = document.getElementById(
                            props.graph.link[item.index]
                        )
                        if (el != null) el.click()
                    }
                },
            },
        },
    }

    return (
        <div className="col" style={{ display: "grid" }}>
            <div style={{ "justify-self": "center", width: props.width }}>
                <Doughnut
                    options={options}
                    plugins={props.plugins}
                    data={props.data}
                />
            </div>
        </div>
    )
}
