import React from "react"
import { useState, useEffect } from "react"

export default function LoadingImg(props) {
    const [loading, setLoading] = useState(false)
    return (
        <div
            class="loadingIconSmall"
            style={
                props.loading
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
            }
        ></div>
    )
}
