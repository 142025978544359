import React from "react"
import { useRef, useState, useEffect } from "react"
import DashboardCard from "./DashboardCard"
import { IN_OUT_STATUS } from "../../settings/app_constants"
import InOutUpdateTime from "./InOutUpdateTime"
import GraphDoughnut from "./GraphDoughnut"
import ChartDataLabels from "chartjs-plugin-datalabels"
import LoadingImg from "../Loading"
import dashboardService from "../../services/dashboard"
import { cssGetProperty } from "../../graph-common"

var __lastLoad = 0

export default function PresenceStatusCardList(props) {
    const moment = () => {
        let el = document.getElementById("IDS_MOMENT_PROSIM")
        return el != null ? el.innerText : ""
    }
    const emptyInfo = { nazov: moment(), pocet: "" }
    const emptyGraph = {
        __total__: 0,
        labels: [""],
        datasets: [
            {
                data: [1],
                borderWidth: 1,
                backgroundColor: ["#e0e0e0"],
            },
        ],
    }
    const [loading, setLoading] = useState(true)
    const [cardData, setCardData] = useState(new Array(6).fill(emptyInfo))
    const [graphData, setGraphData] = useState(new Array(2).fill(emptyGraph))
    const [reload, setReload] = useState(true)

    const cards = [
        {
            tabClass: "dashboard-presence",
            id: "pritNeprit" + IN_OUT_STATUS.ALL + "_lnk",
            zostFilter: IN_OUT_STATUS.ALL, //vsetci
            bgClass: "dashboard-card-color1",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=140&resetFilters=1&intcodeFilter=" +
                IN_OUT_STATUS.ALL,
        },
        {
            tabClass: "dashboard-presence",
            id: "pritNeprit" + IN_OUT_STATUS.IN + "_lnk",
            zostFilter: IN_OUT_STATUS.IN, //pritomni
            bgClass: "dashboard-card-color2",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=140&resetFilters=1&intcodeFilter=" +
                IN_OUT_STATUS.IN,
        },
        {
            tabClass: "dashboard-presence",
            id: "pritNeprit" + IN_OUT_STATUS.OUT + "_lnk",
            zostFilter: IN_OUT_STATUS.OUT, //nepritomni
            bgClass: "dashboard-card-color3",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=140&resetFilters=1&intcodeFilter=" +
                IN_OUT_STATUS.OUT,
        },
        {
            tabClass: "dashboard-presence",
            id:
                "pritNeprit" +
                IN_OUT_STATUS.TOTAL_AGAINST_PLANNED_SHIFT +
                "_lnk",
            zostFilter: IN_OUT_STATUS.TOTAL_AGAINST_PLANNED_SHIFT, //spolu voci planovanej zmene
            bgClass: "dashboard-card-color1",
            refreshFunc: useRef(null),
            /*
            link:
                "getPage.php?rp=140&intcodeFilter=" +
                IN_OUT_STATUS.OUT_AGAINST_PLANNED_SHIFT,
                */
        },
        {
            tabClass: "dashboard-presence",
            id: "pritNeprit" + IN_OUT_STATUS.IN_AGAINST_PLANNED_SHIFT + "_lnk",
            zostFilter: IN_OUT_STATUS.IN_AGAINST_PLANNED_SHIFT, //pritomni voci planovanej zmene
            //style: { backgroundColor: "#24719F" },
            bgClass: "dashboard-card-color6",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=140&resetFilters=1&intcodeFilter=" +
                IN_OUT_STATUS.IN_AGAINST_PLANNED_SHIFT,
        },
        {
            tabClass: "dashboard-presence",
            id: "pritNeprit" + IN_OUT_STATUS.OUT_AGAINST_PLANNED_SHIFT + "_lnk",
            zostFilter: IN_OUT_STATUS.OUT_AGAINST_PLANNED_SHIFT, //nepritomni voci planovanej zmene
            bgClass: "dashboard-card-color5",
            refreshFunc: useRef(null),
            link:
                "getPage.php?rp=140&resetFilters=1&intcodeFilter=" +
                IN_OUT_STATUS.OUT_AGAINST_PLANNED_SHIFT,
        },
    ]

    const evidenceGroup1 = cards.filter(
        (card) =>
            card.zostFilter == IN_OUT_STATUS.ALL ||
            card.zostFilter == IN_OUT_STATUS.IN ||
            card.zostFilter == IN_OUT_STATUS.OUT
    )
    const evidenceGroup2 = cards.filter(
        (card) =>
            card.zostFilter == IN_OUT_STATUS.TOTAL_AGAINST_PLANNED_SHIFT ||
            card.zostFilter == IN_OUT_STATUS.IN_AGAINST_PLANNED_SHIFT ||
            card.zostFilter == IN_OUT_STATUS.OUT_AGAINST_PLANNED_SHIFT
    )

    const graphProps = [
        {
            link: [
                "pritNeprit" + IN_OUT_STATUS.IN + "_lnk",
                "pritNeprit" + IN_OUT_STATUS.OUT + "_lnk",
            ],
        },
        {
            link: [
                "pritNeprit" + IN_OUT_STATUS.IN_AGAINST_PLANNED_SHIFT + "_lnk",
                "pritNeprit" + IN_OUT_STATUS.OUT_AGAINST_PLANNED_SHIFT + "_lnk",
            ],
        },
    ]

    /*
    const rgb2hex = (rgb) => {
        return rgb.indexOf("rgb") >= 0
            ? "#" +
                  rgb
                      .slice(4, -1)
                      .split(",")
                      .map((x) => (+x).toString(16).padStart(2, 0))
                      .join("")
            : rgb
    }

    const cssGetProperty = (id, property) => {
        var el

        if (null != (el = document.getElementById(id)))
            return rgb2hex(
                window.getComputedStyle(el).getPropertyValue(property)
            )

        return undefined
    }
    */

    const makeGraph = (data, ind1, ind2, col1, col2) => {
        let val = [data[ind1].pocet, data[ind2].pocet]
        let sum = val[0] + val[1]

        return sum > 0
            ? {
                  __total__: sum,
                  labels: [data[ind1].nazov, data[ind2].nazov],
                  datasets: [
                      {
                          data: val,
                          borderWidth: 1,
                          backgroundColor: [
                              cssGetProperty(col1, "background-color"),
                              cssGetProperty(col2, "background-color"),
                          ],
                      },
                  ],
              }
            : emptyGraph
    }

    const getData = async () => {
        // pri nacitani stranky taha pritomnost niekedy dva krat, cez useState() to nefunguje, musi byt globalna premenna
        let now = Date.now()
        if (__lastLoad + 5000 > now) return
        __lastLoad = now

        setLoading(true)
        //		console.log('pritomnost')

        const zar = document.querySelectorAll(
            "select[name='zar[]'] option:checked"
        )
        const odd = document.querySelectorAll(
            "select[name='odd[]'] option:checked"
        )
        const vm = document.querySelectorAll(
            "select[name='vm[]'] option:checked"
        )
        /* bez MultiSelect
        const zar = document.querySelectorAll("input[name='zar[]']:checked")
        const odd = document.querySelectorAll("input[name='odd[]']:checked")
        const vm = document.querySelectorAll("input[name='vm[]']:checked")
        */

        const selectedZar = Array.prototype.slice
            .call(zar)
            .map((option) => option.value)
        const selectedOdd = Array.prototype.slice
            .call(odd)
            .map((option) => option.value)
        const selectedVm = Array.prototype.slice
            .call(vm)
            .map((option) => option.value)

        dashboardService
            .getAjaxDataPost(
                {
                    act: "getDashboardInfo2",
                    zar: selectedZar,
                    odd: selectedOdd,
                    vm: selectedVm,
                    filtVeduci:
                        document.getElementById("filtVeduci") != null
                            ? document.getElementById("filtVeduci").value
                            : 0,
                    CSRFToken: document.getElementById("CSRFToken").value,
                },
                {
                    "Content-Type": "multipart/form-data",
                }
            )
            .then((response) => {
                setLoading(false)
                setCardData(response.data)

                setGraphData([
                    makeGraph(
                        response.data,
                        1,
                        2,
                        "colorGraph2",
                        "colorGraph3"
                    ),
                    makeGraph(
                        response.data,
                        4,
                        5,
                        "colorGraph6",
                        "colorGraph5"
                    ),
                ])
            })
            .catch((e) => {
                setLoading(false)
                console.log(e)
            })
    }

    const handleRefresh = () => {
        //getData()
        setReload((previous) => !previous)
    }

    useEffect(() => {
        document
            .getElementById("applyFilter")
            .addEventListener("click", function () {
                //getData()
                setReload((previous) => !previous)
            })
        //getData()
        setReload((previous) => !previous)
    }, [])

    useEffect(() => {
        getData()
    }, [reload, props.reload])

    useEffect(() => {
        if (cardData[0].pocet !== "") {
            $(".dashboard-presence").each(function () {
				if ($(this).tooltip("instance") !== undefined)
					$(this).tooltip("destroy");
                $(this).tooltip({ content: $(this).attr("title") })
            })
        }
    }, [cardData])

    return (
        <div className="h-100">
            <div className="container dashboard-container d-flex flex-column h-100">
                <div className="row">
                    <div className="w-100">
                        <div class="container">
                            <div class="row">
                                <div class="col pr-0">
                                    <b>
                                        {
                                            document.getElementById("pritTxt")
                                                .value
                                        }

                                        <InOutUpdateTime
                                            id="refreshTime"
                                            handleRefresh={handleRefresh}
                                        />
                                    </b>
                                    <LoadingImg loading={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row cardGroupBorder p-0">
                    <div className="container">
                        <div className="row dashboard-container-row">
                            {evidenceGroup1.map((card, index) => (
                                <DashboardCard
                                    tabClass={card.tabClass}
                                    id={card.id}
                                    zostFilter={card.zostFilter}
                                    style={card.style}
                                    bgClass={card.bgClass}
                                    link={card.link}
                                    data={cardData[index]}
                                />
                            ))}
                        </div>
                        <div className="row dashboard-container-row">
                            <GraphDoughnut
                                plugins={[ChartDataLabels]}
                                graph={graphProps[0]}
                                data={graphData[0]}
                                width="200px"
                                useOnClick={true}
                                enableTooltip={graphData[0].__total__ > 0}
                                formatter={(value, ctx) => {
                                    return graphData[0].__total__ > 0
                                        ? (
                                              (value * 100) /
                                              graphData[0].__total__
                                          ).toFixed(0) + "%"
                                        : ""
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row dashboard-container-row cardGroupBorder flex-grow-1">
                    <div className="container">
                        <div className="row dashboard-container-row">
                            {evidenceGroup2.map((card, index) => (
                                <DashboardCard
                                    tabClass={card.tabClass}
                                    id={card.id}
                                    zostFilter={card.zostFilter}
                                    style={card.style}
                                    bgClass={card.bgClass}
                                    link={card.link}
                                    data={cardData[index + 3]}
                                />
                            ))}
                        </div>
                        <div className="row dashboard-container-row">
                            <GraphDoughnut
                                plugins={[ChartDataLabels]}
                                graph={graphProps[1]}
                                data={graphData[1]}
                                width="210px"
                                useOnClick={true}
                                enableTooltip={graphData[1].__total__ > 0}
                                formatter={(value, ctx) => {
                                    return graphData[1].__total__ > 0
                                        ? (
                                              (value * 100) /
                                              graphData[1].__total__
                                          ).toFixed(0) + "%"
                                        : ""
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
