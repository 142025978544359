import React from "react"

export default function DashboardCard(props) {
    return (
        <div className="col d-flex justify-content-center">
            <div
                className={`card text-white mb-3 shadow dashboard-card ${props.bgClass} ${props.tabClass}`}
                style={props.style}
                title={props.data.hint}
            >
                <div className="card-body">
                    <a href={props.link} id={props.id}>
                        <p className="card-text stred">
                            <span className="font-30 bold">
                                {props.data.pocet}
                            </span>
                        </p>
                        <h5 className="card-title stred">{props.data.nazov}</h5>
                    </a>
                </div>
            </div>
        </div>
    )
}
